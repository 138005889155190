<template>
	<div id="add-document" v-if="!serverBusy" class="input-section">
		<div>
			<div v-if="!!serverBusy" id="play-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<fieldset>
				<legend>Add Document Information</legend>
				<div class="input-group">
					<div class="label-input">
						<label for="documentKey" title="Document Key">Document Key</label>
						<input type="text" name="documentKey" placeholder="Document Key" title="Document Key" v-model="documentKey" />
					</div>
					<div class="label-input">
						<label for="documentVersion" title="Document Version">Document Version (date yyyyMMdd)</label>
						<input
							type="number"
							name="documentVersion"
							title="date yyyyMMdd"
							v-model="documentVersion"
						/>
					</div>
					<div class="label-input">
						<label for="documentLanguage" title="Document Language">Document Language</label>
						<input
							type="text"
							name="documentLanguage"
							placeholder="Document Language"
							title="Document Language"
							v-model="documentLanguage"
						/>
					</div>
					<div class="label-input">
						<label for="documentFormat" title="Document Format">Document Format</label>
						<input type="text" name="documentFormat" placeholder="Document Format" title="Document Format" v-model="documentFormat" />
					</div>
					<div class="label-input">
						<label for="documentUrl" title="Document Url">Document Url</label>
						<input type="text" name="documentUrl" placeholder="Document Url" title="Document Url" v-model="documentUrl" />
					</div>
				</div>
			</fieldset>
			<div class="button-container">
				<button id="saveDocumentURL" title="Save Document URL" class="btn" @click="addDocumentReference()">Add Document Information</button>
				<button id="cancel" title="Cancel" class="btn" @click="eventBus.emit('closeModal')">Cancel</button>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "AddDocumentModal",
	props: {
		adminState: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			documentKey: "",
			documentVersion: 0,
			documentLanguage: "",
			documentFormat: "",
			documentUrl: "",
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		async addDocumentReference() {
			this.busyText = "Adding document reference from server";
			this.serverBusy = true;
			this.status.ok = true;

			if (!this.documentKey) {
				this.status.message = "Please provide a key.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.documentVersion > 0) {
				this.status.message = "Please provide a version number (recommended to be a date; yyyyMMdd)";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (this.documentVersion > 2147483647) {
				this.status.message = "Version number too large (recommended to be a date; yyyyMMdd)";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.documentLanguage) {
				this.status.message = "Please fill in a language.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (this.documentLanguage.length > 8) {
				this.status.message = "Language field cannot be longer than 8 characters.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.documentFormat) {
				this.status.message = "Please enter document format.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.documentUrl) {
				this.status.message = "Please enter the URL for the document.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (this.status.ok === false) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				key: this.documentKey,
				version: this.documentVersion,
				language: this.documentLanguage,
				format: this.documentFormat,
				url: this.documentUrl,
			};

			let requestUrl = new URL("/api/v1/document", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				if (fetchStatus?.ok) this.status.message = `Document reference added successfully`;
				this.status.code = fetchStatus?.code;
				this.status.ok = fetchStatus?.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("documentReferenceAdded");
				this.eventBus.emit("closeModal");
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#add-document {
	position: fixed;
	display: grid;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 80%);
	color: #fff;
	backdrop-filter: blur(6px);
	align-items: center;
	justify-content: center;
	z-index: 10;
}

#add-document > div {
	padding: 30px;
	background-color: #000;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

#add-document .input-group {
	display: flex;
	flex-direction: column;
	width: 100%;
}

#add-document .label-input {
	display: flex;
	flex-direction: column;
}

.button-container {
	margin-top: 15px;
}
</style>
